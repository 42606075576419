<template>
    <html>
      <body class="" style="background: #FFF">
        <HeaderMobil :localNavigation="false" v-on:go-to-back="changeGoToBack" />
        <MenuLateralMobile/>
        <div class="h-screen grid grid-cols-6 grid-rows-12 grid-flow-col">
           <MenuLateral/>
            <div class="bg-container col-span-6">
                <div class="w-full md:w-4/5 mx-auto h-auto py-16 grid grid-cols-1 md:grid-cols-2">
                    <div class="px-10 md:px-5 lg:px-20">
                        <p class="text-3xl font-bold mb-10 RobotoBold">Finalizar Trámite con envío de firma</p>
                        <p class="text-2xl font-light mb-5 RobotoRegular text-gray-600">¡Su trámite se ha procesado con éxito!</p>
                        <p class="text-xl font-light mb-14 description">
                            Elija el medio por el que desea que se envie contrato para firma.
                        </p>
                        <input type="button" class="bg-mustardDark cursor-pointer text-white px-5 py-2 mx-auto my-2 w-full md:w-full h-14 md:h-14  rounded text-center font-medium text-xl" value="Enviar por correo"> 
                        <input type="button" class="bg-mustardDark cursor-pointer text-white px-5 py-2 mx-auto my-2 w-full md:w-full h-14 md:h-14  rounded text-center font-medium text-xl" value="Enviar por SMS">  
                    </div>
                    <div class="px-10 py-16 hidden md:block">
                        <img :src="require('@/assets/Cuenta/vidaSimple/finished.svg')" alt="FONCABSA" class="mx-auto">
                    </div>
                </div>
            </div>
        </div>
      </body>
    </html>
</template>

<script>
import Quote from '@/classes/Quote';

import { onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';
import MenuPrincipal from "@/components/Cuenta/MenuPrincipal.vue";
import HeaderMobil from "@/components/Cuenta/HeaderMobil.vue";
import MenuLateralMobile from '@/components/MenuLateralMobile.vue';;
import MenuLateral from '@/components/LateralMenu.vue';;
import Footer from '@/components/Footer.vue';

export default {
    setup(){
        let issuanceObject = ref({});

        const router = useRouter();
        const category = ref('');
        let counterGoBack = ref(0);
        let continueIssuance = ref(false);

        onBeforeMount(async ()=>{
            issuanceObject.value = new Quote();
            category.value = router.currentRoute.value.params.category;
            await loadInfo();
        });

        const loadInfo = async () => {
           let quote = getStorage('quote-data');
           let issuance = getStorage('issuance-data');
           let quoteDetail = getStorage('quote-detail');

           console.log("datos para emision quote:",quote)
           console.log("datos para emision issuance:",issuance)
           console.log("datos para emision quoteDetail:",quoteDetail)
        }

        const createStorage = (name, value) => {
            localStorage.setItem(name, JSON.stringify(value));
        }
        
        const getStorage = (name) => {
            return JSON.parse(localStorage.getItem(name));
        }

        const storageExist = (name) => {
            return (localStorage.getItem(name))? true: false;
        }

        function isEmptyObject(obj) {
            return Object.keys(obj).length === 0;
        }

        const changeGoToBack = () => {
            counterGoBack.value = counterGoBack.value+1;
            console.log("goToBack", counterGoBack.value)
        }

        const finishedIssuance = () => {

        }

        return {
            category,
            changeGoToBack,
            counterGoBack,
            continueIssuance,
            finishedIssuance
        }
    },
    components:{
        MenuPrincipal,
        HeaderMobil,
        MenuLateralMobile,
        MenuLateral,
        Footer
    }
}
</script>

<style scoped>
.description {
    font-family: 'roboto-regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 33px;
    color: #485462;
}
</style>
const host = process.env.VUE_APP_GSAPI;
const hostCCAPI = process.env.VUE_APP_CCAPI;
const key = process.env.VUE_APP_GSAPI_KEY;
import api from '@/api/api';

export default class Quote {

    getToken = async() => {

        const resource = `/token/generate`;
        const API_URL = `${host}${resource}`;
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            apikey: key
        };

        return await api.httpGet(API_URL, config , params);
    }

    post = async(token, params) => {

        const resource = `/vs/quote`;
        const API_URL = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            }
        };

        return await api.httpPost(API_URL, config, params);
    }

    getWithCustomUrl = async(token, url) => {

        const API_URL = url;

        const config = {
            "headers": {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            }
        };

        const params = {};
        return await api.httpGet(API_URL, config, params);
    }

    getUserInformation = async (token, userId) => {

        const resource = `/${business}/persons/vs_info_to_quote`;
        const API_URL = `${hostCCAPI}${resource}`;
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            token_auth: token,
            user_id: userId
        };

        return await api.httpGet(API_URL, config , params);
    }
    
}